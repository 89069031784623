//import * as Sentry from '@sentry/sveltekit';
import { initializeFirebase } from '$lib/firebase/firebase.client';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
/* Sentry.init({
	dsn: 'https://2b09a93378eca55e94d82528b63e0041@o1199461.ingest.us.sentry.io/4507027733151744',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: []
}); */

initializeFirebase();
export const handle = async ({ event, resolve }) => {
	return resolve(event);
};
// export const handleError = Sentry.handleErrorWithSentry();

